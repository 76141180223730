'use strict';

/**
 * Telegram stage. Renders a single Telegram as multiple adjacent 
 * panels that can perform a fold / unfold animation.
 * 
 * @class 
 * 
 * @param {string} tText - Telegram text
 * @param {object} tConfigs - Telegram configs
 * @param {object} [configs] - configs
 * @param {string} [configs.vaðmálId=telegram-stað-vaðmál] - ID of wrapper
 * @param {object} [configs.vaðmál] - wrapper element
 * @param {bool} [configs.folded] - start folded?
 *
 * @prop {object} vaðmál - outermost wrapper html element
 */
function TelegramStaðView(tText, tConfigs, configs){
  var folded, vaðmál, vaðmálId, template, þing;
  
  vaðmálId = (configs && configs.vaðmálId) || TelegramStaðView.vaðmálId;
  vaðmál = (configs && configs.vaðmál) || TelegramStaðView.getVaðmálElement(vaðmálId);
  þing = TelegramStaðView.createÞing();
  template = TelegramStaðView.createTelegramTemplate();
  folded = (configs && configs.folded);

  vaðmál.appendChild(þing);
  vaðmál.className = TelegramStaðView.vaðmálClassName;

  þing.appendChild(template);
  TelegramStaðView.createTelegramView(template, tText, tConfigs);
 
  for (var i = 0; i < 3; i++){
    var cln = template.cloneNode(true);
    cln.classList.remove(TelegramStaðView.telegramTemplateClassName);
    var tp = TelegramStaðView.createTelegramPanel(i);
    þing.appendChild(tp);
    tp.appendChild(cln);
  }

  this.vaðmál = vaðmál;

  if (folded){
    this.vaðmál.classList.add(TelegramStaðView.preFoldedClassName);
    this.fold();
  }
  
}

// Fold animation duration (ms)
TelegramStaðView.animationDuration = 500;
// Folded class name
TelegramStaðView.foldedClassName = 'folded';
// Pre-folded class name
TelegramStaðView.preFoldedClassName = 'pre-folded';
// Telegram template class name
TelegramStaðView.telegramTemplateClassName = 'telegram-stað-telegram-template';
// Telegram panel class name
TelegramStaðView.telegramPanelClassName = 'telegram-stað-telegram-panel';
// Unfolded class name
TelegramStaðView.unFoldedClassName = 'unfolded';
// Wrapper ID
TelegramStaðView.vaðmálId = 'telegram-stað-vaðmál';
// Wrapper class name
TelegramStaðView.vaðmálClassName = 'telegram-stað-vaðmál';
// Container class name
TelegramStaðView.þingClassName = 'telegram-stað-þing';

/**
 * Perform a fold animation
 *
 * @param {function} [callback] - callback to run on completion
 */
TelegramStaðView.prototype.fold = function(callback){
  this.vaðmál.classList.add(TelegramStaðView.foldedClassName);
  this.vaðmál.classList.remove(TelegramStaðView.unFoldedClassName);
  setTimeout(function(){
    if (typeof callback === 'function'){
      callback();
    }
  }, TelegramStaðView.animationDuration);
};

/**
 * Perform an unfold animation
 *
 * @param {function} [callback] - callback to run on completion
 */
TelegramStaðView.prototype.unfold = function(callback){
  this.vaðmál.classList.remove(TelegramStaðView.foldedClassName);
  this.vaðmál.classList.remove(TelegramStaðView.preFoldedClassName);
  this.vaðmál.classList.add(TelegramStaðView.unFoldedClassName);
  setTimeout(function(){
    if (typeof callback === 'function'){
      callback();
    }
  }, TelegramStaðView.animationDuration);
};

/**
 * Find wrapper element in the document 
 *
 * @param {string} [id] - id of wrapper element
 * @throw if wrapper element could not be found.
 * @return {object} - wrapper element 
 */
TelegramStaðView.getVaðmálElement = function(id){
  var vaðmál = document.getElementById(id);
  if (!vaðmál) {
    throw 'wrapper element with ID ' + id + ' could not be found';
  }
  return vaðmál;
};


/**
 * Create container element
 *
 * @param {string} [id] - id of wrapper element
 * @throw if wrapper element could not be found.
 * @return {object} - wrapper element 
 */
TelegramStaðView.createÞing = function(){
  var þ = document.createElement('DIV');
  þ.className = TelegramStaðView.þingClassName;
  return þ;
};

/**
 * Create template element. A template telegram will be initialized
 * here.
 *
 * @throw if wrapper element could not be found.
 * @return {object} - template element 
 */
TelegramStaðView.createTelegramTemplate = function(){
  var v = document.createElement('DIV');
  v.className = TelegramStaðView.telegramTemplateClassName;
  return v;
};

/**
 * Create telegram panel.
 *
 * @param {number} index - panel number
 * @throw if wrapper element could not be found.
 * @return {object} - template element 
 */
TelegramStaðView.createTelegramPanel = function(index){
  var tp = document.createElement('DIV');
  tp.className = TelegramStaðView.telegramPanelClassName + '-' + index;
  return tp;
};

/**
 * Create and render a TelegramView in the given element.
 *
 * @param {object} el - telegram wrapper element
 * @param {string} text - telegram text
 * @param {object} - telegram configs 
 * @throw if wrapper element could not be found.
 * @return {object} - template element 
 */
TelegramStaðView.createTelegramView = function(el, text, configs){
  configs.vaðmál = el;
  return new TelegramView(text, configs);
};