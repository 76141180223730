'use strict';


/**
 * TimestampView.
 * 
 * @class 
 * 
 * @param {object} vaðmál - outer wrapper
 * @param {object} date - date
 * @param {number} scale=1 - scaled size of the timestamp.  
 * @throws if date is invalid
 * @throws if wrapper element could not be found
 */
function TimestampView(vaðmál, date, scale){
  
  var fontSize;

  date = date || new Date();
  vaðmál = vaðmál || TimestampView.defaultVaðmálId;
  if (typeof vaðmál === 'string'){
    vaðmál = document.getElementById(vaðmál);
    if (!vaðmál) {
      throw 'could not find wrapper element with id ' + vaðmál;
    }
  } 
  if (Object.prototype.toString.call(vaðmál) !== '[object HTMLDivElement]'){
    throw 'invalid argument vaðmál: got ' + vaðmál;
  }
  if (Object.prototype.toString.call(date) !== '[object Date]'){
    throw 'date must be a valid Date object, but got ' + date;
  }
  fontSize = TimestampView.computeFontSize(scale);
  vaðmál.appendChild(TimestampView.createÞing(date, fontSize));
}

TimestampView.defaultFontSize = 18;
TimestampView.defaultVaðmálId = 'timestamp-vaðmál';
TimestampView.þingClassName = 'timestamp-þing';
TimestampView.contentClassName = 'timestamp-content';
TimestampView.monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

/**
 * Creates container with backing and content.
 *  
 * @param {object[]} content - Array of HTML elements to insert as content
 * @return {object} - Container element
 */
TimestampView.createÞing = function(date, fontSize){
  var þing = document.createElement('DIV');
  var content = TimestampView.createContent(date, fontSize);
  þing.className = TimestampView.þingClassName;
  þing.appendChild(content);
  return þing;
};

/**
 * Creates content element
 *
 * @param {number} scale - scale
 * @return {string} - computed fontSize attribute
 */
TimestampView.computeFontSize = function(scale){
  return (scale * TimestampView.defaultFontSize) + 'px';
};  

/**
 * Creates content element
 *
 * @param {object} date - Date to render as content
 * @return {object} - Content element
 */
TimestampView.createContent = function(date, fontSize){

  var buff = [];
  var content = document.createElement('P');
  var yyyy = date.getFullYear();
  var mmm = TimestampView.month(date.getMonth());
  var ampm = TimestampView.amPM(date.getHours());
  var hh = TimestampView.hour(date.getHours());
  var mm = TimestampView.minute(date.getMinutes());
  buff = [yyyy, mmm, ampm, hh, mm];

  for (var i = 0; i < buff.length; i++){
    content.appendChild(TimestampView.createStampBlock(buff[i]));
  }

  content.className = TimestampView.contentClassName;
  content.style.fontSize = fontSize;
  return content;
};

/**
 * Return AM / PM
 *
 * @param {number} h - hour
 * @throws if hour is invalid
 * @return {string} - 'AM' or 'PM'
 */
TimestampView.amPM = function(h){
  if (typeof h !== 'number'){
    throw 'h must be a number but got ' + h;
  }
  return (h > 11 ? 'PM' : 'AM');
};

/**
 * Return formatted hour
 *
 * @param {number} i - hour (0 to 23)
 * @throws if i is invalid
 * @return {string} - hour (' 1' to '12')
 */
TimestampView.hour = function(i){
  var h = '';
  if (typeof i !== 'number' || i < 0 || i > 23){
    throw 'congratulations on finding hour ' + i + 'in the day';
  }
  if (i > 12){
    i -= 12;
  }
  h += i;
  if (h.length < 2){
    h = ' ' + h;
  }
  return h;
};

/**
 * Return formatted minute
 *
 * @param {number} i - minute (0 to 59)
 * @throws if i is invalid
 * @return {string} - minute (' 1' to '59')
 */
TimestampView.minute = function(i){
  var m = '';
  if (typeof i !== 'number' || i < 0 || i > 59){
    throw 'i must be between 0 and 59 but got ' + i;
  }
  m += i;
  if (m.length < 2){
    m = '0' + m;
  }
  return m;
};

TimestampView.month = function(i){
  if (typeof i !== 'number' || i > 11 || i < 0){
    throw '`i` must be between 0 and 11 but got ' + i;
  }
  return TimestampView.monthNames[i];
};

/**
 * Creates span element
 *
 * @param {string} str - Text to render
 * @return {object} - HTML SPAN element to insert as content
 */
TimestampView.createStampBlock = function(str){
  var block = document.createElement('SPAN');
  block.innerHTML = str + ' ';
  return block;
};