'use strict';


/**
 * @class 
 */
function Typewriter(){}


Typewriter.defaultInkiness = 1;             // baseline opacity. change to 
                                            //  make *all* text more or less opaque

Typewriter.inkinessScalingFactor = 0.32;    // scaling factor for opacity modifiers

Typewriter.inkinessDictionary = {           // dictionary of opacity modifiers for certain                  
  'A': 0.1022, 'a': 0.1022,                 //  characters
  'R': 0.817,  'r': 0.817,
  'I': 0.753,  'i': 0.753,
  'E': 0.750,  'e': 0.750,
  'N': 0.728,  'n': 0.728,
  'T': 0.542,  't': 0.542,
  'S': 0.504,  's': 0.504,
  'L': 0.459,  'l': 0.459,
  'G': 0.429,  'g': 0.429,
  'U': 0.414,  'u': 0.414,
  'Ð': 0.396,  'ð': 0.396,
  'M': 0.350,  'm': 0.350,
  'H': 0.298,  'h': 0.298,
  'K': 0.286,  'k': 0.286,
  'F': 0.273,  'f': 0.273,
  'O': 0.273,  'o': 0.273,
  'V': 0.260,  'v': 0.260,
  'D': 0.190,  'd': 0.190,
  'Þ': 0.156,  'þ': 0.156,
  'Á': 0.145,  'á': 0.145,
  'Í': 0.131,  'í': 0.131,
  'B': 0.104,  'b': 0.104,
  'J': 0.101,  'j': 0.101,
  'Y': 0.097,  'y': 0.097,
  'P': 0.081,  'p': 0.081,
  'Ó': 0.074,  'ó': 0.074,
  'Æ': 0.074,  'æ': 0.074,
  'Ú': 0.067,  'ú': 0.067,
  'Ö': 0.063,  'ö': 0.063,
  'É': 0.049,  'é': 0.049,
  'Ý': 0.018,  'ý': 0.018,
  'X': 0.010,  'x': 0.010
};


/**
 * Create an array of styled SPAN elements from a String
 *
 * @param {string} str - text to render
 * @throws if str is not given or is not a String
 * @returns {object}[] - array of SPAN elements, each containing one character
 *                            of str
 */
Typewriter.prototype.type = function(str){
  if (!str || typeof str !== 'string'){
    throw '`str` must be a string but got ' + str;
  }
  var buff = [];
  for (var i = 0; i < str.length; i++){
    buff.push(Typewriter.createCharacter(str[i], Typewriter.characterInkiness(str[i])));
  }
  return buff;
};

/**
 * Create a single SPAN element with a single character and a given opacity
 *
 * @param {string} c - character to render
 * @param {number} alpha - character opacity
 * @throws if c is not a single character
 * @throws if alpha is not a number between 0 and 1
 * @returns {object}[] - array of SPAN elements, each containing one character
 *                            of str
 */
Typewriter.createCharacter = function(c, alpha){
  if (!alpha || typeof alpha !== 'number' || alpha < 0 || alpha > 1){
    throw '`alpha` must be a number between 0 and 1, but got ' + alpha;
  }
  if (!c || typeof c !== 'string' || c.length !== 1){
    throw '`c` must be a single character, but but ' + c;
  }
  var span = document.createElement('SPAN');
  span.innerHTML = c;
  span.setAttribute('style', 'opacity:' + alpha + ';');
  return span;
};

/**
 * Calculate the opacity of a character
 *
 * @param {string} c - character 
 * @throws if c is not a single character
 * @returns {object}[] - array of SPAN elements, each containing one character
 *                            of str
 */
Typewriter.characterInkiness = function(c){
  if (!c || typeof c !== 'string' || c.length !== 1){
    throw '`c` must be a single character, but but ' + c;
  }
  var delta = Typewriter.inkinessDictionary[c] || 0;
  var alpha = Typewriter.defaultInkiness - delta * Typewriter.inkinessScalingFactor;
  if (alpha < 0 || alpha > 1){
    console.warn('Character inkiness must be between 0 and 1 but got ' + alpha);
  }
  return alpha;
};
